import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
} from '@angular/common/http';
import { catchError, finalize } from 'rxjs/operators';
import { LoadingService } from '../loading.service';
import { Observable, throwError } from 'rxjs';
@Injectable()
export class LoadingInterceptor {

    constructor(
        private loadingService: LoadingService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.headers.has('show-loading-table')) {
            this.loadingService.toggleLoadingInTable(true);
        }

        return next.handle(request).pipe(
            catchError(
                (error: HttpErrorResponse) => {
                    this.loadingService.handleError(error);
                    return throwError(error);
                }),
            finalize(() => {
                this.loadingService.toggleLoadingInTable(false);
            }));
    }
}
