import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from '../services/cookie.service';
import { ToastrService } from '../services/toastr.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.checkUserValidation()) {
      return true;
    } else {
      this.router.navigate(['account', 'login']);
      return false
    }
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
  checkUserValidation() {
    let user = JSON.parse(this.cookieService.getCookie('user'))
    if (!!user && user.accessToken) {
      let payLoad = this.cookieService.decodeJwtToken(user.accessToken);

      if (payLoad.role == "CONTENT_UPLOADER" || payLoad.role == "ADMIN") {
        return true;
      }
    }

    this.toastr.warning("You don't have access.Relogin with right credentials");
    this.router.navigate(["main"]);
    return false;
  }
}
