export const environment = {
  production: true,
  s3Bucket: {
    accessKeyId: 'AKIATCKAOATEEPV5IR5F',
    secretAccessKey: '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',
    region: 'ap-southeast-1',

    buckets: {
      bucketPath: 'dfix-vods/',
      sourceFolder: 'derana-reality-platform/source/',
      smsFolder: 'derana-reality-platform/sms_vote/',
      destinationFolder: 'derana-reality-platform/destination/',
    },
  },
  realityCmsServiceApi: 'https://cms.stg.reality.rumex.com.lk/',
  realityAuthApi: 'https://auth.stg.reality.rumex.com.lk/',
  realityVoteApi: 'https://voting.stg.reality.rumex.com.lk/',

  imageTypes: {
    sliderGraphicImage: 'SLIDER_GRAPHIC_IMAGE',
    contestantProfilePic: 'CONTESTANT_PROFILE_PIC',
    contestantThumbnail: 'CONTESTANT_THUMBNAIL',
    judgeProfilePic: 'JUDGE_PROFILE_PIC',
    judgeThumbnail: 'JUDGE_THUMBNAIL',
    contestantFeedImage: 'CONTESTANT_FEED_IMAGE',
    roundFeedImage: 'ROUND_FEED_IMAGE',
    programCover: 'PROGRAM_COVER',
    seasonCover: 'SEASON_COVER',
    videoThumbnail: 'VIDEO_THUMBNAIL',
  },
  appUserRoles: {
    admin: 1,
    user: 2,
    judge: 3,
  },
  smsUrlEnum: {
    dialog: 'SOURCE_1',
    mobitel: 'SOURCE_2',
    airtel: 'SOURCE_3'
  },
  apiVersion: 'v1',
  adminUser: 'admin@derana.lk'
};
