import { Injectable } from '@angular/core';
import { NgbToast, NgbToastService, NgbToastType } from 'ngb-toast';

@Injectable({
  providedIn: 'root'
})
export class ToastrService {

  constructor(
    private ngbTostrService: NgbToastService
  ) { }

  success(message: string) {
    const toast: NgbToast = {
      toastType: NgbToastType.Success,
      text: `${message}`,
      dismissible: true,
      timeInSeconds: 3
    }
    this.ngbTostrService.show(toast);
  }
  error(message: string) {
    const toast: NgbToast = {
      toastType: NgbToastType.Danger,
      text: `${message}`,
      dismissible: true,
      timeInSeconds: 3
    }
    this.ngbTostrService.show(toast);
  }
  warning(message: string) {
    const toast: NgbToast = {
      toastType: NgbToastType.Warning,
      text: `${message}`,
      dismissible: true,
      timeInSeconds: 3
    }
    this.ngbTostrService.show(toast);
  }
  info(message: string) {
    const toast: NgbToast = {
      toastType: NgbToastType.Info,
      text: `${message}`,
      dismissible: true,
      timeInSeconds: 3
    }
    this.ngbTostrService.show(toast);
  }
}
