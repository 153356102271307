import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, finalize, map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ToastrService } from '../toastr.service';
import { CookieService } from '../cookie.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private cookieService: CookieService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let isSetToken = true;
    if (request.headers.has('no-token')) {
      isSetToken = false;
    }

    //set token
    const currentUser: any = JSON.parse(this.cookieService.getCookie('user'));
    request = (isSetToken && currentUser && !!currentUser.accessToken) ? this.addToken(request, currentUser.accessToken) : this.addXApiVersion(request);

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) return event;
      }),
      // retry(2),//Retry request if failed one time
      finalize(() => {
      }));
  }
  addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        'X-Api-Version': environment.apiVersion
      }
    });
  }
  addXApiVersion(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        'X-Api-Version': environment.apiVersion
      }
    });
  }
}
