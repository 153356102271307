import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { WarningModalComponent } from 'src/app/shared/warning-modal/warning-modal.component';
import { ToastrService } from './toastr.service';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  public refreshTableLoadingSource = new BehaviorSubject(null);
  initialTableLoadingRefresh = this.refreshTableLoadingSource.asObservable();

  constructor(
    // private matDialog: MatDialog,
    private toastr: ToastrService
  ) { }

  toggleLoadingInTable(isShow: boolean) {
    this.refreshTableLoadingSource.observers = this.refreshTableLoadingSource.observers.slice(this.refreshTableLoadingSource.observers.length - 1);
    this.refreshTableLoadingSource.next(isShow);
  }
  handleError(error: HttpErrorResponse) {
    console.log(error);
    let description: string = "Please try again";
    let message: string = "Something went wrong";

    if (!!error.error.error && !!error.error.error.code) {
      let bError: any = error.error.error;
      let code = bError.code.split("");
      let context = code.slice(0, 3).join("");
      let category = code.slice(3, 5).join("");

      switch (context) {
        case "RMX":
          message = bError.message;
          description = bError.description;
          break;
        case "AUT":
        case "CMS":
        case "CTN":
        case "VOT":
          message = (category == "99") ? "Something went wrong" : bError.message;
          break;
      }
    }
    this.toastr.error(message);
  }
}
